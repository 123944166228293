type Key = number | string

export function groupedBy<T, K extends Key>(items: T[], keyFor: (item: T) => K): Map<K, T[]> {
  return items.reduce((acc, item) => {
    const key = keyFor(item)
    let group = acc.get(key)
    if (!group) {
      group = []
      acc.set(key, group)
    }
    group.push(item)
    return acc
  }, new Map())
}

export function groupBy<T, K extends Key>(items: T[], keyFor: (item: T) => K): Array<T[]> {
  const byKey = groupedBy(items, keyFor)
  return [...byKey.values()]
}

export function partition<T>(items: T[], predicate: (item: T) => boolean): [T[], T[]] {
  const t = []
  const f = []
  for (const item of items) {
    if (predicate(item)) {
      t.push(item)
    } else {
      f.push(item)
    }
  }
  return [t, f]
}

export function* upTo(max: number): Generator<number> {
  for (let i = 0; i < max; i++) {
    yield i
  }
}
